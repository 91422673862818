import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './DownloadPage.css';

import { isPlatform } from '@ionic/react';
import BaseLayout from '../components/Base/BaseLayout';

const DownloadPage = () => {
    const history = useHistory();

    // Logo click => Navigate to home page
    const handleLogoClick = () => {
        history.push('/');
        // or history.push('/') if you prefer
    };

    // Badge click => Open store in a new tab
    const handleBadgeClick = (store) => {
        const appLinks = {
            gplay: 'https://play.google.com/store/apps/details?id=com.bayzar.app',
            apple: 'https://apps.apple.com/us/app/bayzar/id6736984487',
        };
        if (appLinks[store]) {
            window.open(appLinks[store], '_blank');
        }
    };

    useEffect(() => {
        const appLinks = {
            gplay: 'https://play.google.com/store/apps/details?id=com.bayzar.app',
            apple: 'https://apps.apple.com/us/app/bayzar/id6736984487',
        };

        const redirectToStore = () => {
            if (isPlatform('ios')) {
                // Redirect to Apple App Store for iOS devices
                window.location.href = appLinks.apple;
            } else {
                // Redirect to Google Play Store for Android and other platforms
                window.location.href = appLinks.gplay;
            }
        };

        redirectToStore();

        // Optional: If you want to navigate away from /download after redirection
        // setTimeout(() => history.push('/'), 1000);
    }, [history]);

    return (
        <BaseLayout>
            <div className="download-container">
                <img
                    src="/assets/icons/download_logo.png"
                    alt="Bayzar Logo"
                    className="download-logo"
                    onClick={handleLogoClick}
                />

                <div className="download-badges">
                    {/* App Store Badge */}
                    <div
                        className="download-badge"
                        onClick={() => handleBadgeClick('apple')}
                    >
                        <img
                            src="/assets/icons/social/apple.svg"
                            alt="App Store"
                        />
                    </div>

                    {/* Google Play Badge */}
                    <div
                        className="download-badge"
                        onClick={() => handleBadgeClick('gplay')}
                    >
                        <img
                            src="/assets/icons/social/gplay.svg"
                            alt="Google Play"
                        />
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
};

export default DownloadPage;
